import React from 'react'

import Head from 'components/Head'
import Utility from 'templates/Utility'
import paths from 'constants/paths'

export default function ServerError() {
  const title = 'Something went wrong'

  return (
    <>
      <Head title={title} />
      <Utility
        title={`Darn, ${title.toLowerCase()}!`}
        button="Go to home"
        buttonTo={paths.HOME}
        className="ServerError">
        Sorry for the inconvenience, we will notify our engineering team and resolve the issue as
        soon as possible.
      </Utility>
    </>
  )
}
